<template>
  <div class="go_export">
    <Header back="返回" title="数据导出" index="首页" titleOne="数据管理" titleTwo="数据导出" beforeTitle="导出详情" />
    <div class="content">
      <h1>导出详情</h1>
      <ul class="data_detail">
        <li>
          <span>数据类型</span>
          <p style="color: #909399">{{ exportValue }}</p>
        </li>
        <li>
          <span>数据条数</span>
          <p style="color: #0f38ff; font-weight: 700">{{ datalist }}条</p>
        </li>
        <li>
          <span>数据范围</span>
          <p>数据导出最多一次性可导出2000条。</p>
        </li>
      </ul>
      <div class="data_select">
        <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
          <el-form-item
            prop="minNum"
            :rules="[
              { required: true, message: '不能为空' },
              { type: 'number', message: '必须为数字值' },
            ]"
          >
            <el-input type="minNum" placeholder="请输入开始条数" v-model.number="ruleForm.minNum"></el-input>
          </el-form-item>
        </el-form>
        <span>至</span>
        <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
          <el-form-item
            prop="maxNum"
            :rules="[
              { required: true, message: '不能为空' },
              { type: 'number', message: '必须为数字值' },
            ]"
          >
            <el-input placeholder="请输入结束条数" v-model.number="ruleForm.maxNum"></el-input>
          </el-form-item>
        </el-form>
        <p v-if="select <= datalist" class="select">已选择{{ select }}条</p>
        <p style="color: red" class="select" v-if="select > datalist">选择超过最大{{ datalist }}条数</p>
      </div>
      <!-- <el-transfer v-model="exportList" :data="exportList"></el-transfer> -->
      <div class="transfer">
        <div class="tranBox">
          <div class="tranText">
            <span style="color: #404040">数据导出</span>
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
            >全选</el-checkbox>
          </div>
          <div class="box">
            <!-- v-model记录选中的哪个复选框 -->
            <el-checkbox-group v-model="checkList" @change="handleCheckedCitiesChange">
              <el-checkbox
                :class="[item.default == 0 ? 'active' : 'isactive']"
                v-for="(item, index) in exportList"
                :key="index"
                :label="item"
                @change="export_left(index)"
              >{{ item.name }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <img class="tranImg" src="../../assets/images/tranfer.png" alt />
        <div class="tranBox">
          <div class="tranText">
            <span style="color: #404040">已选数据导出</span>
          </div>
          <div class="box">
            <el-checkbox-group v-model="checkList" @change="handleCheck">
              <el-checkbox
                v-for="(item, index) in exportList"
                :key="index"
                :label="item"
                v-show="exportList[index].default == 1"
                @change="export_right(index)"
                :checked="Boolean(item.default == 1)"
              >{{ item.name }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <el-row>
        <el-button class="expoetsure" @click="goExport()" type="primary">确定</el-button>
      </el-row>
    </div>
    <el-dialog :visible.sync="centerDialogVisible" center width="422px" top="18%">
      <h1 class="start">生成进度</h1>
      <h2 class="starts">正在生成合作客户数据，请稍后…..</h2>
      <el-progress :stroke-width="12" :percentage="progress"></el-progress>
      <span slot="footer" class="dialog-footer">
        <el-button
          style="margin-top: 24px"
          type="primary"
          :class="progress < 100 ? 'progress' : 'progressed'"
        >
          <!-- <el-link
            style="color: #fff"
            :href="userSite + file.path"
            :underline="false"
            >点击下载</el-link
          >-->
          <a :href="userSite + file.path" :download="file.name" style="color: #fff">点击下载</a>
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import user from "../../components/global.vue";
export default {
  data () {
    return {
      progress: 1,
      checked: false,
      userSite: user.userSite,
      isAtive: 0,
      centerDialogVisible: false,
      checkAll: false,
      isIndeterminate: true,
      datalist: "",
      select: "0",
      exportType: "",
      ruleForm: {
        minNum: "",
        maxNum: "",
      },
      checkList: [],
      // checkLists: [],
      typelist: [
        {
          type: "partner",
          value: "合作客户",
        },
        {
          type: "potential",
          value: "意向客户",
        },
        {
          type: "patent",
          value: "专利列表",
        },
        {
          type: "soft",
          value: "软著列表",
        },
        {
          type: "project_patent",
          value: "案卷管理",
        },
      ],
      exportList: "",
      file: {
        path: "",
        name: "",
      },
    };
  },
  computed: {
    exportValue () {
      let i = this.typelist.findIndex((item) => item.type == this.exportType);
      return this.typelist[i].value;
    },
  },
  created () {
    //总条数
    if (this.$route.query.item == 'patent') {
      this.getPatentTotal()
    } else if (this.$route.query.item == 'soft') {
      this.getSoftTotal()
    } else if (this.$route.query.item == 'partner') {
      this.getCompanyTotal()
    } else if (this.$route.query.item == 'potential') {
      this.datalist = this.$route.query.total
    } else if (this.$route.query.item == 'project_patent') {
      this.getPatentCount()
    }
    this.exportType = this.$route.query.item;
    if (this.exportType) {
      this.axios
        .get("/api/data/get_export_field", {
          params: { type: this.exportType },
        })
        .then((res) => {
          this.exportList = res.data;
        });
    }
  },
  beforeMount () {
    if (this.exportList.default == 1) {
      this.checked = true;
    } else if (this.exportList.default == 0) {
      this.checked = false;
    }
  },
  updated () {
    // console.log("min:", this.ruleForm.minNum, "max:", this.ruleForm.maxNum);
    if (this.ruleForm.minNum < this.ruleForm.maxNum) {
      const num = this.ruleForm.maxNum - this.ruleForm.minNum + 1;
      this.select = num;
    }
  },
  methods: {
    getPatentCount () {
      this.axios.get('/api/project_patent/export_count', { params: { type: 'project_patent', ...this.$route.query.data } }).then((res) => {
        this.datalist = res.data.count
      })
    },
    // 专利列表数量
    getPatentTotal () {
      if (this.$route.query.data) {
        this.axios.get('/api/patent/export_count', { params: { type: 'patent', ...this.$route.query.data } }).then((res) => {
          this.datalist = res.data.count
        })
      }
    },
    // 软著列表数量
    getSoftTotal () {
      this.axios.get('/api/soft/export_count', { params: { type: 'soft', ...this.$route.query.data } }).then((res) => {
        this.datalist = res.data.count
      })
    },
    // 合作客户数量
    getCompanyTotal () {
      this.axios.get('/api/company/export_count', { params: { type: 'partner' } }).then((res) => {
        this.datalist = res.data.count
      })
    },
    export_right (index) {
      this.exportList[index].default = 0;
      // setTimeout((this.checked = false), 1000);
    },
    export_left (index) {
      this.exportList[index].default = 1;
      // setTimeout((this.nochecked = true), 1000);
      this.checked = true;
      this.nochecked = false;
    },
    handleCheck (value) {
      this.checked = !this.checked;
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.exportList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.exportList.length;
    },
    // 处理数据导出全选
    handleCheckAllChange (val) {
      this.checkList == val ? this.exportList : [];
      this.isIndeterminate = false;
      if (val) {
        this.exportList.forEach((item) => (item.default = 1));
        this.checked = true;
      } else if (!val) {
        this.exportList.forEach((item) => (item.default = 0));
        this.checked = false;
      }
      console.log(val, this.exportList);
    },
    handleCheckedCitiesChange (value) {
      // this.nochecked = !this.nochecked;
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.exportList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.exportList.length;
    },
    // 处理确定按钮
    goExport () {
      var id = [];
      this.exportList.forEach(function (item) {
        if (item.default == 1) {
          id.push(item.id);
        }
      });
      if (this.select > 0) {
        this.centerDialogVisible = true;
        this.axios
          .get("/api/data/export_data", {
            params: {
              type: this.exportType,
              field: id.join(),
              start: this.ruleForm.minNum,
              end: this.ruleForm.maxNum,
              ...this.$route.query.data,
            },
          })
          .then((res) => {
            this.file.path = res.data.path;
            this.file.name = res.data.file_name;
            console.log(this.file);
            this.progress = 100;
          });
      } else {
        this.$message({
          type: "error",
          message: "请选择条数或者条数不能为0条",
        });
      }
    },
  },
};
</script>

<style scoped>
.data_detail li {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #404040;
}
.data_detail li p {
  margin-left: 37px;
}
.data_select {
  display: flex;
  align-items: center;
}
.data_select span {
  margin: 0 14px;
}
.el-input {
  width: 224px;
}
div/deep/.el-form-item__content {
  margin-left: 0 !important;
}
div/deep/.el-form-item {
  margin-bottom: 0 !important;
}
.select {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #404040;
  margin-left: 14px;
}
.el-transfer {
  margin-top: 22px;
}
.transfer {
  display: flex;
  align-items: center;
}
.tranBox {
  width: 490px;
  height: 100%;
  margin-top: 22px;
}
.tranImg {
  width: 24px;
  height: 22px;
  margin: 0 18px;
}
.tranText {
  width: 490px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.box {
  width: 488px;
  height: 481px;
  border: 1px solid #d8dce6;
  box-sizing: border-box;
  padding: 15px;
  overflow: auto;
}
/* /滚动条样式/ */
/* /高宽分别对应横竖滚动条的尺寸/ */
.box::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* /滚动条里面小方块/ */
.box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #eff0f7;
}
div/deep/.el-checkbox {
  display: block;
  margin: 8px 0;
}
.progress {
  background: #edeef4 !important;
  color: #909399 !important;
  border-color: #edeef4;
}
.el-row {
  display: flex;
  justify-content: space-around;
}
.expoetsure {
  margin-top: 50px;
}
.start {
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #000000;
  line-height: 18px;
}
.starts {
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #000000;
  line-height: 16px;
  margin: 16px 0 30px 0;
}
div/deep/.el-progress-bar__outer {
  background: #f2f3f9;
}
div/deep/.el-progress-bar__inner {
  background: linear-gradient(90deg, #0f38ff, #4dd6ff);
}
div/deep/.el-progress-bar {
  width: 95% !important;
}
div/deep/.el-progress__text {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #0f38ff;
  line-height: 14px;
}
div/deep/.isactive {
  display: none;
}
</style>
